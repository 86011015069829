<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import {
    masterMethods,
    keyMaster,
    employeeMethods,
    checkPermission,
    checkPermissionName,
    settingPhoneMethods,
    authFackMethods
} from '@/state/helpers';
import { maxLength, required } from 'vuelidate/lib/validators';
import { regexNumber, isNumber, isValidEmail } from '@/utils/format';
import { showMessage } from '@/utils/messages.js';
import { InputCheckBox, InputText } from '@/components/Input';
import { PHONE_SETTING_EMPLOYEE } from '@/config/var-common.js';
import { state } from '@/state/modules/authfack.js';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'パルディア社員新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        InputCheckBox,
        InputText
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                password: '',
                department: {
                    id: ''
                },
                team: {
                    id: ''
                },
                role: {
                    id: '',
                    name: ''
                },
                note: '',
                account_id: null,
                chatwork_id: '',
                chatwork_token: '',
                phone_setting_type: []
            },
            chatworkTokenShow: '',
            listDataMasterTeam: [],
            listDataMasterGroup: [],
            listDataMasterRoles: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            isTeam: true,
            inValidToken: false,
            chatworkTokenTemp: null,
            isWaitToken: false,
            isInputAccountID: false,
            objTemp: {
                chatwork_token: '',
                account_id: null,
                chatwork_id: ''
            },
            isVerifyToken: false,
            hasButtonCheckToken: true,
            phoneSettingType: PHONE_SETTING_EMPLOYEE
        };
    },

    beforeRouteUpdate(to, from, next) {
        // Xác định nếu có tham số id trong đường dẫn mới
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
        }
        // Gọi next để tiếp tục chuyển route
        next();
    },
    mounted() {
        this.getListMasterGroup();
        this.getListMasterRoles();
    },
    validations: {
        form: {
            name: { required, maxLength: maxLength(255) },
            phone_number: { maxLength: maxLength(20) },
            kana_name: {
                required,
                maxLength: maxLength(255),
                katakana() {
                    if (this.form.kana_name && this.form.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.form.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            email: {
                required,
                email() {
                    if (this.form.email && !isValidEmail(this.form.email.trim())) {
                        return false;
                    }
                    return true;
                },
                maxLength: maxLength(255)
            },
            password: {
                required() {
                    // Kiểm tra queryId bắt buộc nếu tồn tại query id
                    if (!this.id && !this.form.password) {
                        return false;
                    }
                    return true; // Bỏ qua kiểm tra nếu không tồn tại query id
                },
                validate() {
                    if (this.form.password && this.form.password.length < 255) {
                        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                        if (passwordRegex.test(this.form.password)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            role: {
                id: { required }
            },
            team: {
                id: { required }
            },
            account_id: {
                maxLength: maxLength(10)
            },
            chatwork_id: {
                maxLength: maxLength(255)
            },
            chatwork_token: {
                maxLength: maxLength(255)
            }
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...employeeMethods,
        ...settingPhoneMethods,
        ...authFackMethods,

        handleNumber(e) {
            isNumber(e);
        },

        resetForm() {
            this.id = null;
            this.form = {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                password: '',
                department: {
                    id: ''
                },
                team: {
                    id: ''
                },
                role: {
                    id: '',
                    name: ''
                },
                note: '',
                account_id: null,
                chatwork_id: '',
                chatwork_token: '',
                phone_setting_type: []
            };
            this.chatworkTokenShow = '';
            this.isInputAccountID = false;
            this.objTemp = {
                chatwork_token: '',
                account_id: null,
                chatwork_id: ''
            };
        },
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        inputPhone() {
            this.form.phone_number = regexNumber(this.form.phone_number);
        },
        getListMasterRoles() {
            this.listMaster('roles').then((data) => {
                this.listDataMasterRoles = data;
            });
        },
        getListMasterGroup() {
            this.listMaster(keyMaster['employee.department']).then((data) => {
                this.listDataMasterGroup = data;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailEmployee(id).then((data) => {
                if (!data.team) {
                    data.team = {
                        id: ''
                    };
                }
                if (!data.role) {
                    data.role = {
                        id: '',
                        name: ''
                    };
                }
                setTimeout(() => {
                    this.setTeam();
                }, 1000);
                this.form = data;
                this.objTemp = {
                    chatwork_token: data.chatwork_token,
                    account_id: data.account_id,
                    chatwork_id: data.chatwork_id
                };
                if (data.chatwork_token) {
                    this.chatworkTokenShow = data.chatwork_token;
                    this.isInputAccountID = true;
                } else {
                    this.chatworkTokenShow = '';
                    this.isInputAccountID = false;
                }
                this.form.phone_setting_type = data.phone_setting_type?.map((x) => x.type) ?? [];
                this.loading = false;
            });
        },

        register() {
            if (this.form.name && this.form.email && !this.loading) {
                this.loading = true;
                if (this.$route.query.id) {
                    let dataObject = Object.assign(this.form);
                    dataObject.account_id = dataObject?.account_id ? Number(dataObject.account_id) : null;
                    if (!dataObject.password) {
                        delete dataObject.password;
                    }
                    if (dataObject.chatwork_token === this.chatworkTokenShow) {
                        delete dataObject.chatwork_token;
                    }
                    if (!this.checkPermiss(['administrator', 'part_time', 'instructor'])) {
                        this.$router.push({ path: '/employees' }).then(() => {
                            showMessage(8080, this.$bvToast, 'アクセス権限がありません。');
                        });
                    } else {
                        this.updateEmployee(dataObject).then((data) => {
                            if (data.code == 200) {
                                showMessage(data.code, this.$bvToast, 'パルディア社員が更新されました。');
                                this.getDetail(this.$route.query.id);
                                if (this.$route.query.id == state.user.id) this.profile();
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                                this.loading = false;
                            }
                        });
                    }
                } else {
                    if (!this.checkPermiss(['administrator', 'part_time', 'instructor'])) {
                        this.$router.push({ path: '/employees' }).then(() => {
                            showMessage(8080, this.$bvToast, 'パルディア社員が更新されました。');
                        });
                    } else {
                        this.registerEmployee(this.form).then((data) => {
                            if (data.code == 200) {
                                this.$router
                                    .push({
                                        path: '/employee/view',
                                        query: {
                                            id: data.data.id
                                        }
                                    })
                                    .then(() => {
                                        showMessage(data.code, this.$bvToast, 'パルディア社員が登録されました。');
                                    });
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        });
                    }
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            if (this.isVerifyToken) {
                showMessage(8080, this.$bvToast, this.$t('pageEmployee.verifyToken'));
            }
            this.$v.$touch();
            if (
                (this.$v.$invalid && this.isTeam) ||
                this.inValidToken ||
                this.form.account_id?.length > 10 ||
                this.isVerifyToken ||
                !this.$v.form.password.validate ||
                !this.$v.form.password.required ||
                !this.$v.form.email.email
            ) {
                return;
            } else {
                if (this.$v.form.kana_name.katakana) {
                    this.register();
                }
            }
        },
        goToEdit(path, id) {
            this.$router.push({ path: path, query: { id: id } });
        },
        setTeam() {
            this.isTeam = false;
            this.listDataMasterTeam = [];
            let i = this.listDataMasterRoles.findIndex((x) => x.id == this.form.role.id);
            if (i >= 0) {
                this.form.role.name = this.listDataMasterRoles[i].name;
                if (this.form.role.name === 'sale') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_SALE);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                } else if (this.form.role.name === 'instructor') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_INSTRUCTOR);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                }
            }
        },
        handleChangeSetTeam() {
            this.isTeam = false;
            this.listDataMasterTeam = [];
            this.form.team.id = '';
            let i = this.listDataMasterRoles.findIndex((x) => x.id == this.form.role.id);
            if (i >= 0) {
                this.form.role.name = this.listDataMasterRoles[i].name;
                if (this.form.role.name === 'sale') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_SALE);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                } else if (this.form.role.name === 'instructor') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == process.env.VUE_APP_INSTRUCTOR);
                    if (index >= 0) this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                    this.isTeam = true;
                }
            }
        },
        handleCallChatWork() {
            if ((this.chatworkTokenTemp && this.chatworkTokenTemp === this.form.chatwork_token) || !this.form.chatwork_token) return;
            this.chatworkTokenTemp = this.form.chatwork_token;
            let chatwork_token = this.form.chatwork_token;
            this.isWaitToken = false;
            this.getInfoChatWork({ chatwork_token })
                .then((res) => {
                    this.isWaitToken = false;
                    this.isVerifyToken = false;
                    if (!res) {
                        this.inValidToken = true;
                        this.form.account_id = null;
                        this.form.chatwork_id = '';
                        return;
                    }
                    this.inValidToken = false;
                    this.form.account_id = res?.account_id ?? null;
                    this.form.chatwork_id = res?.chatwork_id ?? '';
                    this.objTemp = {
                        account_id: res?.account_id ?? null,
                        chatwork_id: res?.chatwork_id ?? ''
                    };
                })
                .catch((error) => {
                    this.isWaitToken = false;
                    console.log('error', error);
                });
        },
        handleInputChatWorkToken() {
            if (!this.form.chatwork_token) {
                this.inValidToken = false;
                this.isVerifyToken = false;
                this.isInputAccountID = false;
                this.hasButtonCheckToken = true;
                this.form.account_id = null;
                this.form.chatwork_id = '';
            } else {
                this.isInputAccountID = true;
                this.hasButtonCheckToken = false;
                if (this.form.chatwork_token !== this.chatworkTokenTemp) {
                    this.form.account_id = null;
                    this.form.chatwork_id = '';
                    this.isVerifyToken = true;
                } else {
                    this.form.account_id = this.objTemp.account_id;
                    this.form.chatwork_id = this.objTemp.chatwork_id;
                    this.isVerifyToken = false;
                }
            }
        }
    },
    watch: {
        '$route.query.id': {
            handler: function (val) {
                if (val) {
                    this.getDetail(val);
                }
            },
            immediate: true
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom01"
                                            >{{ $t('pageEmployee.name') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom01"
                                                v-model="form.name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.name.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('pageEmployee.name')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('pageEmployee.name')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom02"
                                            >{{ $t('pageEmployee.name_kana') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom02"
                                                v-model="form.kana_name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.kana_name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.kana_name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.kana_name.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('pageEmployee.name_kana')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.kana_name.katakana">{{
                                                    $t('validateField.incorrectFormat', {
                                                        field: $t('pageEmployee.name_kana')
                                                    })
                                                }}</span>

                                                <span v-if="!$v.form.kana_name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('pageEmployee.name_kana')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom03"
                                            >{{ $t('pageEmployee.email') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom03"
                                                :model.sync="form.email"
                                                :config="{
                                                    isRequired: !$v.form.email.required,
                                                    isMaxLength: !$v.form.email.maxLength,
                                                    isEmail: !$v.form.email.email,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.email.$error,
                                                    errorField: $t('pageEmployee.email')
                                                }"
                                                @change="submitted = false"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom04"
                                            >{{ $t('pageEmployee.password') }}<span class="text-danger">*</span>
                                        </label>

                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom04"
                                                v-model="form.password"
                                                type="password"
                                                autocomplete="new-password"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.password.$error
                                                }"
                                            />
                                            <div>
                                                <small v-if="this.$route.query.id && !$v.form.password.$error" style="font-size: 14px">{{
                                                    $t('notificationCommon.notePass2')
                                                }}</small>
                                                <small v-else-if="!$v.form.password.$error">{{ $t('notificationCommon.notePass') }}</small>
                                            </div>

                                            <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.password.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('pageEmployee.password')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.password.validate">
                                                    {{ $t('notificationCommon.notePass') }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployee.cellphoneNumber') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.phone_number"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.phone_number.$error
                                                }"
                                                @input="inputPhone"
                                            />
                                            <div v-if="submitted && $v.form.phone_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.phone_number.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: $t('pageEmployee.cellphoneNumber')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="tokenUser">{{ $t('pageEmployee.tokenUser') }}</label>
                                        <div class="col-sm-12">
                                            <div class="d-flex">
                                                <div class="w-100">
                                                    <input
                                                        id="tokenUser"
                                                        v-model="form.chatwork_token"
                                                        type="text"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': (submitted && $v.form.chatwork_token.$error) || inValidToken
                                                        }"
                                                        @input="handleInputChatWorkToken"
                                                    />
                                                    <div v-if="inValidToken || (submitted && $v.form.chatwork_token.$error)" class="invalid-feedback">
                                                        <span v-if="!$v.form.chatwork_token.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: $t('pageEmployee.tokenUser')
                                                            })
                                                        }}</span>
                                                        <span v-else class="text-danger"> {{ $t('pageEmployee.tokenInvalid') }} </span>
                                                    </div>
                                                </div>
                                                <div class="ml-3">
                                                    <b-button
                                                        :disabled="hasButtonCheckToken"
                                                        variant="success"
                                                        @click="handleCallChatWork"
                                                        :class="{
                                                            disabled_cursor: hasButtonCheckToken
                                                        }"
                                                        style="white-space: nowrap"
                                                        >{{ $t('btn.verify') }}</b-button
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="accountId">{{ $t('pageEmployee.accountId') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="accountId"
                                                v-model="form.account_id"
                                                type="number"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.account_id.$error,
                                                    disabled_cursor: isInputAccountID
                                                }"
                                                :disabled="isInputAccountID"
                                                @keypress="handleNumber($event)"
                                            />
                                            <div v-if="submitted && $v.form.account_id.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.account_id.maxLength">{{
                                                    $t('validateField.max10', {
                                                        field: $t('pageEmployee.accountId')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="chatworkId">{{ $t('pageEmployee.chatworkId') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="chatworkId"
                                                v-model="form.chatwork_id"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.chatwork_id.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.chatwork_id.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.chatwork_id.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('pageEmployee.chatworkId')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-2 mw--form d-none d-sm-block" style="padding-right: 0">
                                            <hr />
                                        </div>
                                        <div class="col-sm-12" style="padding-left: 0">
                                            <hr />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom06"
                                            >{{ $t('pageEmployee.role') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-md-6" style="min-width: 220px">
                                            <select
                                                class="form-control"
                                                v-model="form.role.id"
                                                @change="handleChangeSetTeam()"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.role.$error
                                                }"
                                            >
                                                <template v-for="item in listDataMasterRoles">
                                                    <option v-if="item.name !== 'partner'" :key="item.id" :value="item.id">
                                                        {{ $t(item.name) }}
                                                    </option>
                                                </template>
                                            </select>
                                            <div v-if="submitted && $v.form.role.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.role.required">{{
                                                    $t('validateField.requiredSelect', {
                                                        field: $t('pageEmployee.role')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" v-if="isTeam && form.role.name !== 'part_time' && form.role.name !== 'administrator'">
                                        <label class="col-sm-2 mw--form" for="validationCustom07"
                                            >{{ $t('pageEmployee.team') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-md-6" style="min-width: 220px">
                                            <select
                                                class="form-control"
                                                v-model="form.team.id"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.team.$error
                                                }"
                                            >
                                                <option v-for="item in listDataMasterTeam" :key="item.id" :value="item.id">
                                                    {{ item.value }}
                                                </option>
                                            </select>
                                            <div v-if="submitted && $v.form.team.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.team.required">{{
                                                    $t('validateField.requiredSelect', {
                                                        field: $t('pageEmployee.team')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom07">{{ $t('pageEmployee.roleSettingPhone') }}</label>
                                        <div class="col-md-6" style="min-width: 220px">
                                            <div v-for="(stp, k) in phoneSettingType" :key="k">
                                                <InputCheckBox
                                                    :model.sync="form.phone_setting_type"
                                                    :config="{
                                                        id: `check_phone_setting_type${k}`,
                                                        value: stp.id,
                                                        label: stp.label
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom09">{{ $t('pageEmployee.description') }}</label>
                                        <div class="col-sm-12">
                                            <textarea rows="5" id="validationCustom09" v-model="form.note" class="form-control"></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/employees'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermission('employee.register') || (checkPermission('employee.edit') && $route.query.id)"
                            type="submit"
                            @click="formSubmit"
                            class="btn btn-primary ml-3"
                            :disabled="isWaitToken"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style scoped></style>
