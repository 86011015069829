var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(!_vm.loading)?_c('div',{staticClass:"row form__body--card form__common--center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-6"},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom01"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.name'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.name.$error
                                            },attrs:{"id":"validationCustom01","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('pageEmployee.name') })))]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pageEmployee.name') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom02"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.name_kana'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kana_name),expression:"form.kana_name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.kana_name.$error
                                            },attrs:{"id":"validationCustom02","type":"text"},domProps:{"value":(_vm.form.kana_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kana_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.kana_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.kana_name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('pageEmployee.name_kana') })))]):_vm._e(),(!_vm.$v.form.kana_name.katakana)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.incorrectFormat', { field: _vm.$t('pageEmployee.name_kana') })))]):_vm._e(),(!_vm.$v.form.kana_name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pageEmployee.name_kana') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom03"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.email'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom03","model":_vm.form.email,"config":{
                                                isRequired: !_vm.$v.form.email.required,
                                                isMaxLength: !_vm.$v.form.email.maxLength,
                                                isEmail: !_vm.$v.form.email.email,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.email.$error,
                                                errorField: _vm.$t('pageEmployee.email')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "email", $event)},"change":function($event){_vm.submitted = false}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom04"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.password'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.password.$error
                                            },attrs:{"id":"validationCustom04","type":"password","autocomplete":"new-password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('div',[(this.$route.query.id && !_vm.$v.form.password.$error)?_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('notificationCommon.notePass2')))]):(!_vm.$v.form.password.$error)?_c('small',[_vm._v(_vm._s(_vm.$t('notificationCommon.notePass')))]):_vm._e()]),(_vm.submitted && _vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.password.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('pageEmployee.password') })))]):_vm._e(),(!_vm.$v.form.password.validate)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('notificationCommon.notePass'))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.cellphoneNumber')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.phone_number.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.phone_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone_number", $event.target.value)},_vm.inputPhone]}}),(_vm.submitted && _vm.$v.form.phone_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phone_number.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('pageEmployee.cellphoneNumber') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"tokenUser"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.tokenUser')))]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.chatwork_token),expression:"form.chatwork_token"}],staticClass:"form-control",class:{
                                                        'is-invalid': (_vm.submitted && _vm.$v.form.chatwork_token.$error) || _vm.inValidToken
                                                    },attrs:{"id":"tokenUser","type":"text"},domProps:{"value":(_vm.form.chatwork_token)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "chatwork_token", $event.target.value)},_vm.handleInputChatWorkToken]}}),(_vm.inValidToken || (_vm.submitted && _vm.$v.form.chatwork_token.$error))?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.chatwork_token.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pageEmployee.tokenUser') })))]):_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('pageEmployee.tokenInvalid'))+" ")])]):_vm._e()]),_c('div',{staticClass:"ml-3"},[_c('b-button',{class:{
                                                        disabled_cursor: _vm.hasButtonCheckToken
                                                    },staticStyle:{"white-space":"nowrap"},attrs:{"disabled":_vm.hasButtonCheckToken,"variant":"success"},on:{"click":_vm.handleCallChatWork}},[_vm._v(_vm._s(_vm.$t('btn.verify')))])],1)])])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"accountId"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.accountId')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.account_id),expression:"form.account_id"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.account_id.$error,
                                                disabled_cursor: _vm.isInputAccountID
                                            },attrs:{"id":"accountId","type":"number","disabled":_vm.isInputAccountID},domProps:{"value":(_vm.form.account_id)},on:{"keypress":function($event){return _vm.handleNumber($event)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "account_id", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.account_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.account_id.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max10', { field: _vm.$t('pageEmployee.accountId') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"chatworkId"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.chatworkId')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.chatwork_id),expression:"form.chatwork_id"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.chatwork_id.$error
                                            },attrs:{"id":"chatworkId","type":"text"},domProps:{"value":(_vm.form.chatwork_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "chatwork_id", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.chatwork_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.chatwork_id.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pageEmployee.chatworkId') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-sm-2 mw--form d-none d-sm-block",staticStyle:{"padding-right":"0"}},[_c('hr')]),_c('div',{staticClass:"col-sm-12",staticStyle:{"padding-left":"0"}},[_c('hr')])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom06"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.role'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-md-6",staticStyle:{"min-width":"220px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.role.id),expression:"form.role.id"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.role.$error
                                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.role, "id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.handleChangeSetTeam()}]}},[_vm._l((_vm.listDataMasterRoles),function(item){return [(item.name !== 'partner')?_c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]):_vm._e()]})],2),(_vm.submitted && _vm.$v.form.role.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.role.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.requiredSelect', { field: _vm.$t('pageEmployee.role') })))]):_vm._e()]):_vm._e()])]),(_vm.isTeam && _vm.form.role.name !== 'part_time' && _vm.form.role.name !== 'administrator')?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom07"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.team'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-md-6",staticStyle:{"min-width":"220px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.team.id),expression:"form.team.id"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.team.$error
                                            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.team, "id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.listDataMasterTeam),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0),(_vm.submitted && _vm.$v.form.team.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.team.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.requiredSelect', { field: _vm.$t('pageEmployee.team') })))]):_vm._e()]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom07"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.roleSettingPhone')))]),_c('div',{staticClass:"col-md-6",staticStyle:{"min-width":"220px"}},_vm._l((_vm.phoneSettingType),function(stp,k){return _c('div',{key:k},[_c('InputCheckBox',{attrs:{"model":_vm.form.phone_setting_type,"config":{
                                                    id: `check_phone_setting_type${k}`,
                                                    value: stp.id,
                                                    label: stp.label
                                                }},on:{"update:model":function($event){return _vm.$set(_vm.form, "phone_setting_type", $event)}}})],1)}),0)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom09"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.description')))]),_c('div',{staticClass:"col-sm-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.note),expression:"form.note"}],staticClass:"form-control",attrs:{"rows":"5","id":"validationCustom09"},domProps:{"value":(_vm.form.note)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "note", $event.target.value)}}})])])])])])])])]),_c('Footer',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-light btn-sm mr-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                                path: '/employees'
                            })}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(_vm.checkPermission('employee.register') || (_vm.checkPermission('employee.edit') && _vm.$route.query.id))?_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit","disabled":_vm.isWaitToken},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.$route.query.id ? _vm.$t('btn.save') : _vm.$t('btn.register'))+" ")]):_vm._e()])])])],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('LoadingIcon')],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }